
import Vue, { PropType } from "vue";
import { Exercise } from "@prestonly/preston-common";
import { DialogCloseType, DialogI } from "@/types/dialog";

import TagChips from "@/components/base/TagChips.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";

declare interface Data {
  loading: boolean;
  search: string;
  selectedExercises: Exercise[];
}

export default Vue.extend({
  name: "AttachExistingExerciseDialog",
  components: {
    SearchTextField,
    BaseDialog,
    TagChips,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  data(): Data {
    return {
      loading: true,
      search: "",
      selectedExercises: [],
    };
  },

  computed: {
    exercises(): Exercise[] {
      const exercises = this.$store.getters["exercise/getSearchResults"];
      const selectedExerciseIds = this.selectedExercises.map((l) => l._id);
      return exercises.filter((exercise) => !selectedExerciseIds.includes(exercise._id));
    },
  },

  methods: {
    selectExercise(exercise: Exercise) {
      this.selectedExercises.push(exercise);
    },
    unselectExercise(exercise: Exercise) {
      this.selectedExercises = this.selectedExercises.filter((selected: Exercise) => selected._id !== exercise._id);
    },
    async getExercises(): Promise<void> {
      this.loading = true;
      let filters = "";
      if (this.search) {
        filters = `$search:${this.search}`;
      }
      await this.$store.dispatch("exercise/getList", {
        filters,
        limit: 50,
        page: 1,
      });
      this.loading = false;
    },
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          exercises: this.selectedExercises,
        },
      });
    },
  },
});
